.help-popup-outer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
}

/* Responsive */
@media only screen and (max-width: 500px) {
  .help-pop-up-inner {
    /* height: 60%; */
    width: 80%;
  }
}

@media only screen and (min-width: 501px) {
  .help-pop-up-inner {
    /* height: 500px; */
    width: 400px;
  }
}

.help-pop-up-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  color: white;
  background-color: rgb(29, 29, 29);
  & h2 {
    margin: 0;
    margin-top: 20px;
    width: 100%;
    text-align: center;
  }
  & p {
    text-align: center;
  }
}

.cross-help-div {
  height: 35px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  & div {
    height: 100%;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    & .help-cross-icon {
      height: 25px;
      width: 25px;
      cursor: pointer;
      margin-bottom: 0;
    }
  }
}

.help-cross-icon:hover {
  transform: scale(1.3);
}

.help-and-support-form {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  & .help-name-div,
  .help-email-div {
    height: 40px;
    width: 90%;
    margin-top: 20px;
    color: black;
    & input {
      padding: 5px;
      height: 100%;
      width: 100%;
      border: none;
      outline: none;
      font-size: 17px;
      border-radius: 10px;
    }
  }
  & .help-textarea-div {
    width: 90%;
    margin-top: 20px;
    margin-bottom: 10px;
    color: black;
    & textarea {
      padding: 5px;
      width: 100%;
      border-radius: 10px;
      border: none;
      outline: none;
      resize: none;
      font-size: 17px;
    }
  }
  & button {
    height: 40px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
    border-radius: 30px;
    border: none;
    outline: none;
    color: white;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* .help-and-support-form button:hover{
    background-color: #0000ffe4;
} */

.send-disabled-btn-true {
  background-color: #5757ffe4;
  cursor: not-allowed;
  opacity: 0.3;
}

.send-disabled-btn-false {
  background-color: #0c0cf3e4;
  cursor: pointer;
}
