main{
    overflow-y:scroll;
    color: black;
}
main::-webkit-scrollbar{
    display: none;
}
@media only screen and (max-width : 700px) {
    main{
        height: 92vh;
        width: 100vw;
        /* margin: 0; */
    }  
}

@media only screen and (min-width : 701px) {
    main{
        height: 90vh;
        width: 100vw;
    }  
}