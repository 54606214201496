@media only screen and (max-width: 450px) {
  .all-program-div {
    width: 100%;
  }
  .gallery {
    width: 100%;
    /* overflow: hidden; */
  }
  .each-program {
    width: 80%;
    aspect-ratio: 1/1.2;
  }
}

@media only screen and (min-width: 451px) and (max-width: 700px) {
  .all-program-div {
    width: 100%;
  }
  .gallery {
    width: 100%;
    /* overflow: hidden; */
  }
  .each-program {
    width: 60%;
    aspect-ratio: 1/1;
  }
}

@media only screen and (min-width: 701px) and (max-width: 1000px) {
  .all-program-div {
    width: 40%;
  }
  .gallery {
    width: 60%;
  }
  .each-program {
    width: 95%;
    aspect-ratio: 1/1;
  }
}

@media only screen and (min-width: 1001px) {
  .all-program-div {
    width: 25%;
  }
  .gallery {
    width: 75%;
  }
  .each-program {
    width: 100%;
    aspect-ratio: 1/1;
  }
}

.programs {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.all-program-div {
  height: 100%;
  background-color: rgb(236, 236, 236);
  & .all-program-div-head {
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    /* margin-bottom: 20px; */
    color: darkgreen;
  }
}

.program-div {
  height: 90%;
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  & .scrolling-program-div {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.program-div::-webkit-scrollbar {
  display: none;
}

.background-program {
  width: 100%;
  aspect-ratio: 1/1.2;
  opacity: 0.4;
  & img {
    width: 100%;
    aspect-ratio: 1/1.2;
    /* height: 100%; */
    overflow: hidden;
    border-radius: 10px;
  }
}
.each-program {
  height: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  position: relative;
  border-radius: 10px;
  border: 3px solid rgb(51, 255, 0);
  cursor: pointer;
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.program-content {
  /* padding: 20px; */
  width: 100%;
  /* height: auto; */
  /* aspect-ratio: 1/1; */
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  & .upcoming-first-program {
    color: blueviolet;
  }
  & h3 {
    margin: 0;
    margin-top: 20px;
    font-size: 20px;
    text-align: center;
    /* color: yellow; */
  }
  & h4 {
    margin: 0;
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
    color: darkorange;
    font-size: 20px;
  }
  & p {
    color: white;
    width: 90%;
    margin: 0;
    /* margin-top: 0;
        margin-bottom: 0; */
    margin-bottom: 5px;
    padding: 0;
    text-align: justify;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: center;
  }
}

.visite-gallery,
.visite-upcoming-program {
  height: 30px;
  width: 90%;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.visite-gallery {
  color: white;
  background-color: rgb(252, 104, 54);
}
.visite-upcoming-program {
  margin-top: 10px;
  color: white;
  background-color: rgb(86, 86, 255);
}
.visite-upcoming-program:hover {
  background-color: rgb(0, 0, 255);
}
.visite-gallery:hover {
  background-color: rgb(255, 64, 0);
}
